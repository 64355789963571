import React, { memo } from "react";
import Button from "../Button/Button";
import Text from "../Text/Text";
import IconResource from "../../../Wrapper/IconResource/IconResource";
import { Link } from "react-router-dom";
import { createWhatsAppLink, shouldWhatsappShow } from "../../../Services/common";
import CustomTooltip from "../Tooltip/CustomTooltip";
import { NO_MOBILE_NUMBER } from "../../../Constants/stringConstants";

const NavBar = memo(({
  heading,
  onClick,
  button,
  buttonIcon,
  buttonIconsuff,
  setModalShow,
  Icon,
  button2,
  buttonIcon2,
  buttonIconsuff2,
  button2onclick,
  button3,
  buttonIcon3,
  buttonIconsuff3,
  button3onclick,
  contactLink,
  mobileMno
}) => {
  const rightButtonHandler = () => {
    setModalShow(true);
  };
  return (
    <>
      <div className="nav_bar">
        <div className="start">
          <Button
            buttonType="icon"
            icon={<IconResource type={Icon} size={20} />}
            color="black"
            onClick={onClick}
          />
          <Text type="h2" text={heading} />
        </div>
        <div className="d-flex align-items-center gap-2">
          {button ? (
            <Button
              buttonType="primary"
              prefixIcon={
                buttonIcon ? <IconResource type={buttonIcon} size={18} /> : ""
              }
              suffixIcon={
                buttonIconsuff ? (
                  <IconResource type={buttonIconsuff} size={18} />
                ) : (
                  ""
                )
              }
              text={button}
              onClick={rightButtonHandler}
            />
          ) : null}
          {button2 ? (
            <Button
              buttonType="primary"
              prefixIcon={
                buttonIcon2 ? <IconResource type={buttonIcon2} size={18} /> : ""
              }
              suffixIcon={
                buttonIconsuff2 ? (
                  <IconResource type={buttonIconsuff2} size={18} />
                ) : (
                  ""
                )
              }
              text={button2}
              onClick={button2onclick}
            />
          ) : null}
          {
            contactLink ? (
              <CustomTooltip text={!shouldWhatsappShow(mobileMno?.mno) && NO_MOBILE_NUMBER} type="tooltip" placement="top">
                <Button
                  buttonType="primary"
                  disabled={!shouldWhatsappShow(mobileMno?.mno)}
                  prefixIcon={
                    buttonIcon3 ? <IconResource size={20} type={shouldWhatsappShow(mobileMno?.mno) ? "whatsapp_no_color" : "whatsapp"} /> : ""
                  }
                  suffixIcon={
                    buttonIconsuff3 ? (
                      <IconResource type={shouldWhatsappShow(mobileMno?.mno) ? "whatsapp_no_color" : "whatsapp"}

                        size={20} />
                    ) : (
                      ""
                    )
                  }
                  text={"Click on Whatsapp"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (shouldWhatsappShow(mobileMno?.mno)) {
                      window.open(createWhatsAppLink(mobileMno?.mno, mobileMno?.name))
                    }
                  }}
                //      onClick={() => window.open(createWhatsAppLink(mobileMno?.mno, mobileMno?.name))}
                />
              </CustomTooltip>


            ) : null
          }
          {button3 ? (
            <Button
              buttonType="primary"
              prefixIcon={
                buttonIcon3 ? <IconResource type={buttonIcon3} size={18} /> : ""
              }
              suffixIcon={
                buttonIconsuff3 ? (
                  <IconResource type={buttonIconsuff3} size={18} />
                ) : (
                  ""
                )
              }
              text={button3}
              onClick={button3onclick}
            />
          ) : null}
        </div>
      </div>
    </>
  );
});

export default NavBar;
