import { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import { DATEPICKER_PLACEHOLDER } from "../../../Constants/stringConstants";
import * as storageConstants from "../../../Constants/storageConstant";
import IconResource from "../../../Wrapper/IconResource/IconResource";
import { Row, Col, Dropdown } from "react-bootstrap";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

function DateRangePicker({
  startDate,
  showDateShortcuts,
  setStartDate,
  endDate,
  setEndDate,
  datePickerStartRef,
  datePickerEndRef,
  onChange,
  allowFutureDates = false,
}) {
  const [startValue, setStartValue] = useState(startDate);
  const [endValue, setEndValue] = useState(endDate);
  useEffect(() => {
    setStartValue(startDate ? new Date(startDate) : null);
    setEndValue(endDate ? new Date(endDate) : null);
  }, [startDate, endDate]);
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setStartValue(date);
    setTimeout(() => datePickerEndRef?.current?.focus(), 0);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setEndValue(date);
  };

  const ShortcutType = {
    "Today": {
      key: "TODAY",
      displayValue: "Today",
    },
    "Yesterday": {
      key: "YESTERDAY",
      displayValue: "Yesterday",
    },
    "This Month": {
      key: "THIS_MONTH",
      displayValue: "This Month",
    },
    "Last Month": {
      key: "LAST_MONTH",
      displayValue: "Last Month",
    },
    "This Year": {
      key: "THIS_YEAR",
      displayValue: "This Year",
    },
    "Last Year": {
      key: "LAST_YEAR",
      displayValue: "Last Year",
    },
    Clear: {
      key: "CLEAR",
      displayValue: "Clear",
    },
  };

  const handleShortcutClick = (shortcut, e) => {
    const today = new Date();
    let start, end = null;
    e.preventDefault();

    switch (shortcut) {
      case ShortcutType["Today"].displayValue:
        start = today;
        end = today;
        break;
      case ShortcutType["Yesterday"].displayValue:
        start = new Date(today);
        start.setDate(today.getDate() - 1);
        end = new Date(today);
        end.setDate(today.getDate() - 1);
        break;
      case ShortcutType["This Month"].displayValue:
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case ShortcutType["Last Month"].displayValue:
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case ShortcutType["This Year"].displayValue:
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 11, 31);
        break;
      case ShortcutType["Last Year"].displayValue:
        start = new Date(today.getFullYear() - 1, 0, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case ShortcutType["Clear"].displayValue:
        start = null;
        end = null;
        break;
      default:
        return;
    }

    setStartValue(start);
    setEndValue(end);
    onChange(start, end);
  };

  return (
    <div>
      <Row className="date-picker mx-0">
        <Col
          md={5}
          xs={showDateShortcuts ? 5 : 6}
          sm={5}
          className="px-0 me-2 me-sm-0"
        >
          <div className="form-input-group w-100">
            <div className="input-group flex-nowrap">
              <span className="input-group-text" id="basic-addon1">
                <IconResource
                  color="var(--primary)"
                  fontSize={"20px"}
                  type={startValue ? "cross" : ""}
                  onClick={() => handleStartDateChange(null)}
                />
              </span>
              <DatePicker
                ref={datePickerStartRef}
                value={startValue}
                onChange={handleStartDateChange}
                format="dd/MM/yyyy"
                calendarIcon={<IconResource type="calendar" color="var(--primary)" />}
                clearIcon={null}
                maxDate={allowFutureDates ? null : new Date()}
                placeholder={DATEPICKER_PLACEHOLDER}
              />
            </div>
          </div>
        </Col>
        <Col
          md={showDateShortcuts ? 1 : 2}
          xs={0}
          sm={1}
          className="px-0 my-0 d-none d-sm-block"
        >
          <div
            style={{ minWidth: "25px" }}
            className="d-flex align-items-center justify-content-center h-100 "
          >
            <IconResource
              type="rightArrow"
              fontSize={40}
              color={"var(--primary)"}
            />
          </div>
        </Col>
        <Col md={5} xs={5} sm={5} className="px-0">
          <div className="form-input-group w-100">
            <div className="input-group flex-nowrap">
              <span className="input-group-text" id="basic-addon1">
                <IconResource
                  color="var(--primary)"
                  fontSize={"20px"}
                  type={endValue ? "cross" : ""}
                  onClick={() => handleEndDateChange(null)}
                />
              </span>
              <DatePicker
                ref={datePickerEndRef}
                value={endValue}
                onChange={handleEndDateChange}
                format="dd/MM/yyyy"
                clearIcon={null}
                calendarIcon={<IconResource type="calendar" color="var(--primary)" />}
                minDate={startValue}
                maxDate={allowFutureDates ? null : new Date()}
                placeholder={DATEPICKER_PLACEHOLDER}
              />
            </div>
          </div>
        </Col>
        {showDateShortcuts ? (
          <Col md={1} xs={1} className="pe-0 ps-0 ps-md-2">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <IconResource
                  type={"dropdown"}
                  size={20}
                  color="var(--primary)"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(ShortcutType).map((key) => (
                  <Dropdown.Item
                    key={key}
                    onClick={(e) => handleShortcutClick(ShortcutType[key].displayValue, e)}
                  >
                    {ShortcutType[key].displayValue}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default DateRangePicker;