import * as apiProvider from './provider';
import * as urlConstants from '../Constants/urlConstants';
import { isSuperAdmin } from './common';

export function login(reqData) {
  return apiProvider.post(urlConstants.LOGIN, reqData);
}

export function logout(reqData) {
  return apiProvider.post(urlConstants.LOGOUT, reqData);
}

export function resetPassword(reqData) {
  return apiProvider.post(urlConstants.RESET_PASSWORD, reqData)
}

export function changePassword(reqData) {
  return apiProvider.post(urlConstants.CHANGE_PASSWORD, reqData)
}

export function forgotPassword(reqData) {
  return apiProvider.post(urlConstants.FORGOT_PASSWORD, reqData)
}

export function masterData() {
  return apiProvider.getAll(urlConstants.MASTER_DATA);
}

export function systemData() {
  return apiProvider.getAll(urlConstants.SYSTEM_DATA);
}

export function getProfile() {
  return apiProvider.getAll(urlConstants.GET_PROFILE);
}

export function getMedicineList(query) {
  return apiProvider.getAll(urlConstants.GET_MEDICINE_LIST + query);
}

export function getMedicineTypeList(query) {
  return apiProvider.getAll(urlConstants.GET_MEDICINE_TYPE_LIST + query);
}

export function getServiceTypeList(query) {
  return apiProvider.getAll(urlConstants.GET_SERVICE_TYPE_LIST + query);
}

export function getDepartmentList(query) {
  return apiProvider.getAll(urlConstants.GET_DEPARTMENT_LIST + query);
}

export function getSupplierList(query) {
  return apiProvider.getAll(urlConstants.GET_SUPPLIER_LIST + query);
}

export function getServiceList(query) {
  return apiProvider.getAll(urlConstants.GET_SERVICE_LIST + query);
}

export function getHospitalList(query) {
  return apiProvider.getAll(urlConstants.GET_HOSPITAL_LIST + query);
}

export function addMedicine(reqData) {
  return apiProvider.post(urlConstants.ADD_MEDICINE, reqData);
}

export function addMedicineType(reqData) {
  return apiProvider.post(urlConstants.ADD_MEDICINE_TYPE, reqData);
}

export function addServiceType(reqData) {
  return apiProvider.post(urlConstants.ADD_SERVICE_TYPE, reqData);

}

export function addHospital(reqData) {
  return apiProvider.post(urlConstants.ADD_HOSPITAL, reqData);

}

export function addDepartment(reqData) {
  return apiProvider.post(urlConstants.ADD_DEPARTMENT, reqData);

}

export function addSupplier(reqData) {
  return apiProvider.post(urlConstants.ADD_SUPPLIER, reqData);

}

export function addService(reqData) {
  return apiProvider.post(urlConstants.ADD_SERVICE, reqData);
}

export function getReports(query) {
  return apiProvider.getAll(urlConstants.GET_REPORTS + query);
}

export function editMedicine(id, reqData) {
  return apiProvider.post(urlConstants.EDIT_MEDICINE + id, reqData);
}

export function editHospital(reqData) {
  return apiProvider.post(urlConstants.EDIT_HOSPITAL, reqData);
}

export function editDepartment(reqData) {
  return apiProvider.post(urlConstants.EDIT_DEPARTMENT, reqData);
}

export function editSupplier(reqData) {
  return apiProvider.post(urlConstants.EDIT_SUPPLIER, reqData);
}

export function editService(id, reqData) {
  return apiProvider.post(urlConstants.EDIT_SERVICE + id, reqData);
}

export function editServiceType(id, reqData) {
  return apiProvider.post(urlConstants.EDIT_SERVICE_TYPE + id, reqData);
}

export function editMedicineType(id, reqData) {
  return apiProvider.post(urlConstants.EDIT_MEDICINE_TYPE + id, reqData);
}

export function changeSupplierStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_SUPPLIER_STATUS + id, reqData);
}

export function changeHospitalStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_HOSPITAL_STATUS + id, reqData);
}

export function changeDepartmentStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_DEPARTMENT_STATUS + id, reqData);
}

export function changeServiceStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_SERVICE_STATUS + id, reqData);
}

export function changeServiceTypeStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_SERVICE_TYPE_STATUS + id, reqData);
}

export function changeMedicineStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_MEDICINE_STATUS + id, reqData);
}

export function changeMedicineTypeStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_MEDICINE_TYPE_STATUS + id, reqData);
}

export function deleteMedicine(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_MEDICINE + id);
}

export function getPurchaseMedicineList(query) {
  return apiProvider.getAll(urlConstants.GET_PURCHASE_MEDICINE_LIST + query);
}

export function importMaster(query, reqData) {
  return apiProvider.post(urlConstants.IMPORT_MASTER + query, reqData);
}

export function exportMaster(query, reqData) {
  return apiProvider.post(urlConstants.EXPORT_MASTER + query, reqData);
}

export function addPurchaseMedicine(id, reqData) {
  return apiProvider.post(urlConstants.ADD_PURCHASE_MEDICINE + id, reqData);
}

export function editPurchaseMedicine(id, reqData) {
  return apiProvider.post(urlConstants.EDIT_PURCHASE_MEDICINE + id, reqData);
}

export function addProductReturn(reqData) {
  return apiProvider.post(urlConstants.MEDICINE_RETURN, reqData);
}

export function deletePurchaseMedicine(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_PURCHASE_MEDICINE + id);
}

export function searchPatient(query) {
  return apiProvider.getAll(urlConstants.SEARCH_PATIENT + query);
}

export function addPatientVisit(reqData) {
  return apiProvider.post(urlConstants.ADD_PATIENT_VISIT, reqData);
}

export function getPatientsList(reqData) {
  return apiProvider.post(urlConstants.GET_PATIENTS_LIST, reqData);
}
export function getPatientsVisitList(reqData) {
  return apiProvider.post(urlConstants.GET_PATIENTS_NEXT_LIST, reqData);
}
export function getPatientDetailsWithVisits(id) {
  return apiProvider.getAll(urlConstants.GET_PATIENT_DETAILS_WITH_VISITS + id);
}

export function updatePatientData(id, reqData) {
  return apiProvider.post(urlConstants.UPDATE_PATIENT_DETAILS + id, reqData);
}

export function addPrescription(id, reqData) {
  return apiProvider.post(urlConstants.ADD_PRESCRIPTION + id, reqData);
}

export function getPrescription(id) {
  return apiProvider.getAll(urlConstants.GET_PRESCRIPTION + id);
}

export function getMedicineOrder(id) {
  return apiProvider.getAll(urlConstants.GET_MEDICINE_ORDER + id);
}

export function addDAMS(reqData) {
  return apiProvider.post(urlConstants.ADD_DAMS, reqData);
}

export function patientMedicinePurchase(id, reqData) {
  return apiProvider.post(urlConstants.PATIENT_PURCHASE_MEDICINE + id, reqData);
}
export function cancelBil(id, reqData) {
  return apiProvider.post(urlConstants.PATIENT_PURCHASE_MEDICINE_CANCEL + id, reqData);
}

export function saleReturnList(query) {
  return apiProvider.getAll(urlConstants.SALE_RETURN_LIST + query);
}

export function addSaleReturn(reqData) {
  return apiProvider.post(urlConstants.ADD_SALE_RETURN, reqData);
}

export function getUsers(query, reqData) {
  return apiProvider.post(urlConstants.GET_USERS_LIST + query, reqData);
}

export function upsertUser(reqData) {
  return apiProvider.post(urlConstants.UPSERT_USERS, reqData);
}

export function changeUserStatus(query) {
  return apiProvider.post(urlConstants.CHANGE_USER_STATUS + query);
}

export function getDashboardData(query) {
  return apiProvider.getAll(isSuperAdmin()
    ? urlConstants.GET_SUPER_ADMIN_DASHBOARD_DATA
    : (urlConstants.GET_DASHBOARD_DATA + query));
}

export function getDashboardGraphs(query = '') {
  return apiProvider.post(urlConstants.GET_DASHBOARD_GRAPHS + query);
}

export const getReportTypes = (query) => {
  return apiProvider.getAll(urlConstants.GET_REPORT_TYPES + query);
}

export const getMedicineSaleHistory = (query) => {
  return apiProvider.post(urlConstants.MEDICINE_SALE_HISTORY + query);
}

export const getServiceSaleHistory = (query) => {
  return apiProvider.post(urlConstants.SERVICE_SALE_HISTORY + query);
}

export const submitExportDataRequest = (query) => {
  return apiProvider.post(urlConstants.EXPORT_DATA_REQ + query);
}

export const exportDataList = (query) => {
  return apiProvider.post(urlConstants.EXPORT_DATA_LIST + query);
}

export const deleteExportData = (id) => {
  return apiProvider.deleteAPI(urlConstants.DELETE_EXPORT_DATA + id);
}

export const getInvoicePDF = (query) => {
  return apiProvider.post(urlConstants.INVOICE_ORDER_PDF + query);
}

export const getPatientInvestigationDetails = (query) => {
  return apiProvider.post(urlConstants.PATIENT_INVESTIGATION_DETAILS + query);
}

export const upsertPatientInvestigationDetails = (reqData) => {
  return apiProvider.post(urlConstants.PATIENT_INVESTIGATION_DETAILS, reqData);
}

export const getHospitalDoctorInfo = (id) => {
  return apiProvider.getAll(urlConstants.HOSPITAL_DOCTORS + id);
}

export const upsertHospitalDoctorInfo = (reqData) => {
  return apiProvider.post(urlConstants.HOSPITAL_DOCTORS + 'upsert', reqData);
}

export const getAttendanceList = (query) => {
  return apiProvider.post(urlConstants.ATTENDANCE + query);
}

export const addAttendance = (reqData) => {
  return apiProvider.post(urlConstants.ATTENDANCE + '/upsert', reqData);
}
export function getExpenseCategoryList(query) {
  return apiProvider.getAll(urlConstants.GET_EXPENSE_CATEGORY_LIST + query);
}
export function addExpenseCategory(reqData) {
  return apiProvider.post(urlConstants.ADD_EXPENSE_CATEGORY, reqData);
}
export function editExpenseCategory(id, reqData) {
  return apiProvider.post(urlConstants.EDIT_EXPENSE_CATEGORY + id, reqData);
}

export function changeExpenseCategoryStatus(id, reqData) {
  return apiProvider.post(urlConstants.CHANGE_EXPENSE_CATEGORY_STATUS + id, reqData);
}
export function getExpenseList(query) {
  return apiProvider.getAll(urlConstants.GET_EXPENSE_LIST + query);
}
export function addExpense(reqData) {
  return apiProvider.post(urlConstants.ADD_EXPENSE, reqData);
}
export function editExpense(id, reqData) {
  return apiProvider.post(urlConstants.EDIT_EXPENSE + id, reqData);
}
export function deleteExpense(id) {
  return apiProvider.deleteAPI(urlConstants.DELETE_EXPENSE + id);
}
export function calanderStats(query) {
  return apiProvider.post(urlConstants.CALENDAR_STATS + query);
}
