import './App.css';
import { useEffect, useState, Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import * as commonServices from './Services/common';
import * as storageConstants from './Constants/storageConstant';
import { isNullOrEmpty } from './Utils/stringUtils';
import { getProfile } from './Services/ApiHelper';
import { warn } from './Wrapper/toast/toast';
import { MODULES } from './Constants/appConstants';
import { IMAGE_URL } from './Constants/urlConstants';
import { CONFIGS } from './Constants/appConstants';
import ProtectedRoute from './Protected/Protected';
import MainLoader from './Components/_widgets/Loader/MainLoader';
import ENVConfig from './ENVConfig';
import ExpenseCategory from './Components/Expense/ExpenseCategory/ExpenseCategory';
import Expense from './Components/Expense/Expense/Expense';

const NotFound = lazy(() => import('./Components/NotFound/NotFound'));
const LoginLayout = lazy(() => import('./Components/Layouts/LoginLayout'));
const HomeLayout = lazy(() => import('./Components/Layouts/HomeLayout'));
const Login = lazy(() => import('./Components/Login/Login'));
const ChangePassword = lazy(() => import('./Components/ChangePassword/ChangePassword'));
const ResetPassword = lazy(() => import('./Components/ResetPassword/ResetPassword'));
const Dashboard = lazy(() => import('./Components/Dashboard/Dashboard'));
const Patients = lazy(() => import('./Components/Patients/Patients'));
const PatientDetail = lazy(() => import('./Components/Patients/PatientDetail'));
const Users = lazy(() => import('./Components/Users/Users'));
const MedicinesList = lazy(() => import('./Components/Medicines/MedicinesList/MedicinesList'));
const PurchaseMedicines = lazy(() => import('./Components/Medicines/PurchaseMedicines/PurchaseMedicines'));
const SaleReturn = lazy(() => import('./Components/SaleReturn/SaleReturn'));
const Reports = lazy(() => import('./Components/Reports/Reports'));
const ViewReport = lazy(() => import('./Components/Reports/ViewReport'));
const Help = lazy(() => import('./Components/Help/Help'));
const Suppliers = lazy(() => import('./Components/DataManagement/Suppliers/Suppliers'));
const Departments = lazy(() => import('./Components/DataManagement/Departments/Departments'));
const Hospitals = lazy(() => import('./Components/Hospitals/Hospitals'));
const Medicines = lazy(() => import('./Components/DataManagement/Medicines/Medicines'));
const MedicineTypes = lazy(() => import('./Components/DataManagement/MedicineType/MedicineType'));
const Services = lazy(() => import('./Components/DataManagement/Services/Services'));
const ServiceTypes = lazy(() => import('./Components/DataManagement/ServiceType/ServiceType'));
const Export = lazy(() => import('./Components/DataManagement/Export/Export'));
const Attendance = lazy(() => import('./Components/Attendance/Attendance'));
const CalendarView = lazy(() => import("./Components/Patients/Calendar/Calander"))
const NextVisit = lazy(() => import("./Components/Patients/NextVisitListing/NextVisit"))

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState({ main: false, profile: false });


  useEffect(() => {
    const data = commonServices.getLocalData(storageConstants.MASTER_DATA);
    const sysData = commonServices.getLocalData(storageConstants.SYSTEM_DATA);
    const auth = commonServices.getLocalData(storageConstants.AUTH);

    if (isNullOrEmpty(data)) {
      commonServices.fetchMasterData(state => setLoading({ ...loading, main: state }));
    }

    if (auth && isNullOrEmpty(sysData)) {
      commonServices.fetchSystemData(state => setLoading({ ...loading, main: state }));
    }

    window.scrollTo(0, 0);

    const lastVisitedPage = commonServices.getLocalData(storageConstants.LAST_VISITED_PAGE) || {};

    if (!location.pathname.includes('patient')) {
      commonServices.storeLocalData(storageConstants.LAST_VISITED_PAGE, { ...lastVisitedPage, 'patient': null });
    } else if (!location.pathname.includes('report')) {
      commonServices.storeLocalData(storageConstants.LAST_VISITED_PAGE, { ...lastVisitedPage, 'report': null });
    }
  }, [location.pathname]);

  useEffect(() => {
    !commonServices.isSuperAdmin() && commonServices.getLocalData(storageConstants.AUTH) && getProfileDetails();
  }, [commonServices.getLocalData(storageConstants.AUTH)]);

  useEffect(() => {
    const color = commonServices.getLocalData(storageConstants.HOSPITAL_DATA)?.color;
    !isNullOrEmpty(color) && commonServices.setPrimarySecondaryColors(color);
  }, [commonServices.getLocalData(storageConstants.HOSPITAL_DATA)?.color]);

  const getProfileDetails = () => {
    setLoading({ ...loading, profile: true });
    getProfile().then(response => {
      if (response?.isSuccess) {
        const hospital_data = {
          id: response?.data?.hospital_id,
          name: response?.data?.hospital?.name,
          logo: response?.data?.hospital?.logo ? IMAGE_URL + response?.data?.hospital?.logo : null,
          color: response?.data?.hospital?.primary_color,
          address: response?.data?.hospital?.address,
          mno: response?.data?.hospital?.mno,
          services: response?.data?.hospital?.hospital_services,
        }

        commonServices.storeLocalData(storageConstants.PERMISSIONS, response?.data?.hospital?.permissions);
        commonServices.storeLocalData(storageConstants.CONFIG, response?.data?.hospital?.config);
        commonServices.storeLocalData(storageConstants.INVESTIGATION, response?.data?.hospital?.investigation);
        commonServices.storeLocalData(storageConstants.PRESCRIPTION_DOCTORS, response?.data?.hospital?.hospital_doctor);
        commonServices.storeLocalData(storageConstants.HOSPITAL_DATA, hospital_data);
      } else {
        warn(response?.message);
      }
      setLoading({ ...loading, profile: false });
    });
  };

  const { DASHBOARD_MODULE, MEDICINES_MODULE, SERVICIES_MODULE, REPORTS_MODULE, ATTENDANCE_MODULE, VIEW_EXPANSE, VIEW_CALANDER } = MODULES;

  return (
    <div className="App">

      {process.env.REACT_APP_ENV !== "production" && (
        <div className="ribbon ribbon-top-left">
          <span>{process.env.REACT_APP_ENV}</span>
        </div>
      )}

      <ENVConfig />
      <ToastContainer />

      <Suspense fallback={<MainLoader />}>
        {loading.main || loading.profile
          ? <MainLoader />
          : <Routes>
            <Route element={<LoginLayout />} >
              <Route exact path='/' element={<ProtectedRoute Component={<Login location={location} />} />} />
              <Route exact path='/reset-password' element={<ResetPassword location={location} />} />
              <Route exact path="/*" element={<NotFound />} />
            </Route>

            <Route element={<HomeLayout />}>
              <Route exact path="/change-password" element={<ProtectedRoute pageName='/change-password' Component={<ChangePassword />} />} />
              <Route exact path="/dashboard" element={<ProtectedRoute pageName='/dashboard' moduleName={DASHBOARD_MODULE} Component={<Dashboard />} />} />
              <Route exact path="/patients" element={<ProtectedRoute pageName='/patients' Component={<Patients />} />} />
              <Route exact path="/next-visit" element={<ProtectedRoute pageName='/next-visit' configControl={CONFIGS.NEXT_VISIT_LISTING} Component={<NextVisit />} />} />
              <Route exact path="/calendar" element={<ProtectedRoute configControl={CONFIGS.CALENDAR_VIEW} pageName='/calendar' Component={<CalendarView />} />} />
              <Route exact path="/patient-visit/:visitId" element={<ProtectedRoute pageName='/patient-visit' Component={<PatientDetail />} />} />
              <Route exact path="/users" element={<ProtectedRoute pageName='/users' Component={<Users />} />} />
              <Route exact path="/departments" element={<ProtectedRoute pageName='/departments' Component={<Departments />} />} />
              <Route exact path="/suppliers" element={<ProtectedRoute pageName='/suppliers' moduleName={MEDICINES_MODULE} Component={<Suppliers />} />} />
              <Route exact path="/services" element={<ProtectedRoute pageName='/services' moduleName={SERVICIES_MODULE} Component={<Services />} />} />
              <Route exact path="/service-type" element={<ProtectedRoute pageName='/service-type' moduleName={SERVICIES_MODULE} Component={<ServiceTypes />} />} />
              <Route exact path="/medicine-type" element={<ProtectedRoute pageName='/medicine-type' moduleName={MEDICINES_MODULE} Component={<MedicineTypes />} />} />
              <Route exact path="/meds" element={<ProtectedRoute pageName='/meds' Component={<Medicines />} />} />
              <Route exact path="/medicines" element={<ProtectedRoute pageName='/medicines' moduleName={MEDICINES_MODULE} Component={<MedicinesList />} />} />
              <Route exact path="/purchase-medicines" element={<ProtectedRoute pageName='/purchase-medicines' moduleName={MEDICINES_MODULE} Component={<PurchaseMedicines />} />} />
              <Route exact path="/sale-return" element={<ProtectedRoute pageName='/sale-return' moduleName={MEDICINES_MODULE} Component={<SaleReturn />} />} />
              <Route exact path="/reports" element={<ProtectedRoute pageName='/reports' moduleName={REPORTS_MODULE} Component={<Reports />} />} />
              <Route exact path="/hospitals" element={<ProtectedRoute pageName='/hospitals' Component={<Hospitals />} />} />
              <Route exact path="/view-report" element={<ProtectedRoute pageName='/reports' moduleName={REPORTS_MODULE} Component={<ViewReport />} />} />
              <Route exact path="/export" element={<ProtectedRoute pageName='/export' Component={<Export />} />} />
              <Route exact path="/attendance" element={<ProtectedRoute pageName='/attendance' moduleName={ATTENDANCE_MODULE} Component={<Attendance />} />} />
              <Route exact path="/help" element={<ProtectedRoute pageName='/help' Component={<Help />} />} />
              <Route exact path="/expense-category" element={<ProtectedRoute pageName='/expense-category' moduleName={VIEW_EXPANSE} Component={<ExpenseCategory />} />} />
              <Route exact path="/expense" element={<ProtectedRoute moduleName={VIEW_EXPANSE} pageName='/expense' Component={<Expense />} />} />
            </Route>
          </Routes>}
      </Suspense>
    </div >
  );
}

export default App;
