import * as appConstant from '../Constants/appConstants';

export const getColorFromStatus = (value) => {
    let color = '';

    switch (value) {
        case appConstant.ACTIVE:
            color = 'success';
            break;
        case appConstant.INACTIVE:
            color = 'fail';
            break;
        default:
            color = 'var(--counselor)';
            break;
    }

    return color;
}

export const getColorOfStatus = (value) => {
    let color = '';

    switch (value) {
        case appConstant.PENDING:
            color = 'primary';
            break;
        case appConstant.CLOSED:
            color = 'fail';
            break;
        case appConstant.CANCELLED:
            color = 'fail';
            break;
        case appConstant.AT_DOCTOR:
            color = 'doctor';
            break;
        case appConstant.COMPLETED:
            color = 'success';
            break;
        case appConstant.AT_COUNSELLOR:
            color = 'primary';
            break;
        case appConstant.AT_PHARMACY:
            color = 'secondary';
            break;
        default:
            color = 'primary';
            break;
    }

    return color;
}

